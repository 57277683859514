:root {
  /* Font Family */
  --font-family-primary: 'Lato', sans-serif;
  --font-family-secondary: 'Roboto', sans-serif;
  --font-family-tertiary: 'Poppins', sans-serif;

  /* Font sizes */
  --font-size-heading: 2rem;
  --font-size-subheading: 1.5rem;
  --font-size-body: 1rem;
  --font-size-title: 1.25rem;
  --font-size-caption: 0.875rem;

  /* Colors */
  --color-primary: #3498db;       /* Primary color */
  --color-secondary: #2ecc71;     /* Secondary color */
  --color-background: #f5f5f5;    /* Background color */
  --color-text: #333;             /* Main text color */
  --color-muted: #777;            /* Muted text color */
  --color-border: #ddd;     
  --color-bg: #071952;      /* Border color */
}

body {
  background-color: var(--color-background);
  font-family: var(--font-family-primary);
  min-height: 100vh; /* Ensure the body covers the full viewport height */
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the App container covers the full viewport height */
}

.Navbar {
  background-color: var(--color-primary);
}

.Footer {
  background-color: var(--color-secondary);
  margin-top: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@font-face {
  font-family: 'CabinetGrotesk-Bold';
  src: url('./assets/fonts/CabinetGrotesk-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

.customfont {
  font-family: 'CabinetGrotesk-Bold', sans-serif;
  font-weight: bold;
  font-size: 1rem;
}

.heroHeading {
  font-family: 'CabinetGrotesk-Bold', sans-serif;
  font-weight: bold;
}


/* ourSerives start */
.carousel-container {
  position: relative;
}

.carousel-blur-left,
.carousel-blur-right {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100px;
  z-index: 1;
}

.carousel-blur-left {
  left: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  filter: blur(5px);
}

.carousel-blur-right {
  right: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  filter: blur(5px);
}

/* Adjust other styles as needed */
.service-card {
  margin: 15px;
}

.service-card img {
  height: 200px;
  object-fit: cover;
}

.service-card .card-body {
  text-align: center;
}
/* ourSerives end */


/* servcice page start */

.custom-image {
  width: 400px;
  height: 400px;
  object-fit: contain !important; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.fade-enter {
  opacity: 0;
  transform: translateY(20px);
}
.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.fade-exit {
  opacity: 1;
  transform: translateY(0);
}
.fade-exit-active {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 300ms, transform 300ms;
}


/* servcice page end */



/* contact start */
#contact {
  /* background-color: #FFE162; */
  background-color: #dcdcdc48;

}



.msg-form {
  background-color: white;
  padding: 20px
}

#contact .map {
 display: none;
}

@media screen and (min-width:768px) {
  #contact .map {
      display: block;
   }
}

/* contact end */


/* contact form */


.form-container {
  width: 100%;
  /* height: 600px; */
  /* max-width: 350px; */
  background: linear-gradient(#212121, #212121) padding-box,
              linear-gradient(145deg, transparent 35%,#e81cff, var(--color-primary)) border-box;
  border: 2px solid transparent;
  padding: 32px 24px;
  font-size: 14px;
  font-family: inherit;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  background-size: 200% 100%;
  animation: gradient 5s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.form-container button:active {
  scale: 0.95;
}

.form-container .form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-container .form-group {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.form-container .form-group label {
  display: block;
  margin-bottom: 5px;
  color: #717171;
  font-weight: 600;
  font-size: 12px;
}

.form-container .form-group input {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  color: #fff;
  font-family: inherit;
  background-color: transparent;
  border: 1px solid #414141;
}

.form-container .form-group textarea {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  resize: none;
  color: #fff;
  height: 96px;
  border: 1px solid #414141;
  background-color: transparent;
  font-family: inherit;
}

.form-container .form-group input::placeholder {
  opacity: 0.5;
}

.form-container .form-group input:focus {
  outline: none;
  border-color: var(--color-primary);
}

.form-container .form-group textarea:focus {
  outline: none;
  border-color: var(--color-primary);
}

.form-container .form-submit-btn {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-self: flex-start;
  font-family: inherit;
  color: #717171;
  font-weight: 600;
  width: 40%;
  background: #313131;
  border: 1px solid #414141;
  padding: 12px 16px;
  font-size: inherit;
  gap: 8px;
  margin-top: 8px;
  cursor: pointer;
  border-radius: 6px;
}

.form-container .form-submit-btn:hover {
  background-color: #fff;
  border-color: #fff;
}

.contact-text {
  color: rgba(255, 255, 255, 0.81);
  font-size: 0.9rem;
  font-family: 'poppins';
  font-weight: 200;
}

.fas {
  color: white;
  padding-right: 16px;
  padding-top: 12px;
}

.contact-text-container {
  height: 500px;
}

.map-responsive iframe {
  left: 0;
  top: 0;
  padding:  20px 0;
  width: 100%;
  height: 270px;
}

/* contatc form end */

.heroImage {
  height: 400px !important;
  width: 100%;
  object-fit: cover;
  box-shadow: 0 4px 14px var(--color-primary);
  opacity: 0; /* Start as fully transparent */
  animation: fadeIn 2s ease-out forwards;
}

.heroImageMobile {
  height: 300px !important;
  width: 100%;
  object-fit: cover;
  box-shadow: 0 4px 14px var(--color-primary);
  opacity: 0; /* Start as fully transparent */
  animation: fadeIn 2s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0; /* Start from fully transparent */
  }
  to {
    opacity: 1; /* End at fully opaque */
  }
}

.heroTyping {
  font-size: 2.0rem;
  animation: fadeIn 2s ease-out forwards;
}

.custom-font {
  font-family: 'CabinetGrotesk-Bold' !important;
  src: url('./assets/fonts/CabinetGrotesk-Bold.ttf') format('truetype');
  font-size: 0.8rem;
}


.contact-btn {
  width: 9em;
  height: 3em;
  border-radius: 30em;
  font-size: 1rem !important;
  font-family: 'CabinetGrotesk-Bold' !important;
  src: url('./assets/fonts/CabinetGrotesk-Bold.ttf') format('truetype');
  border: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
  /* color: var(--color-primary) !important; */
 }
 
 .contact-btn::before {
  content: '';
  width: 0;
  height: 3em;
  border-radius: 30em;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to right, #fff 0%, #fff 100%);
  color: white !important;
  transition: .5s ease;
  display: block;
  z-index: -1;
 }
 
 .contact-btn:hover::before {
  width: 9em;
 }



/* about us mobile */



/* about us mobile end */

















